.pointer {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.pointer:not(.pointer-hidden)::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  margin-left: -3px;
  z-index: 1;
  border-radius: 50%;
}

.pointer.pointer-light::before {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
}

.pointer.pointer-dark::before {
  background-color: #050022;
}

@keyframes pointer-loading {
  0% { background-color: rgba(48, 55, 67, 1); }
  50% { background-color: rgba(48, 55, 67, 0.35); }
  100% { background-color: rgba(48, 55, 67, 1); }
}

.pointer.pointer-loading::before {
  animation-name: pointer-loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.pointer.pointer-warning::before {
  background-color: var(--color-solid, #EC5378);
  box-shadow: 0px 0px 5px var(--color-solid, rgba(243, 27, 79, 0.5));
  -moz-box-shadow: 0px 0px 5px var(--color-solid, rgba(243, 27, 79, 0.5));
  -webkit-box-shadow: 0px 0px 5px var(--color-solid, rgba(243, 27, 79, 0.5));
}

.pointer.pointer-information::before {
  background-color: #3A3D4E;
}

.pointer.bigger::before {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}