.hint {
  position: relative;
  z-index: 15;
}

.hint > .info {
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: max-content; 
  width: -moz-max-content; 
  width: -webkit-max-content; 
  max-width: 380px;
  cursor: default;
  white-space: pre-wrap;
  opacity: 0;
}

.hint > button:disabled {
  pointer-events: none;
}

.hint.top > .info {
  bottom: 100%;
  margin-bottom: 4px;
}

.hint.bottom > .info {
  top: 100%;
  margin-top: 4px;
}

.hint:hover > .info {
  visibility: visible;
  opacity: 1;
}

.hint-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  column-gap: 4px;
  -webkit-column-gap: 4px;
  flex-direction: row;
  align-items: center;
}

.button-help {
  margin-top: -4px;
  margin-bottom: -4px;
  margin-right: -6px;
  padding: 6px;
  border-radius: 4px;
  opacity: 0.5;
}

.button-help:hover {
  opacity: 1;
}

.button-help img {
  margin: 0 auto
}