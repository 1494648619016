/*** NOT FOUND ***/
.element-not-found {
  text-align: center;
  max-width: 360px;
  margin: 0 auto;
  padding: 18px 0;
}

.element-not-found > *:not(:first-child) {
  margin-top: 5px;
}

.element-not-found .place-bottom {
  margin-top: 10px;
}

.element-not-found.v-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.element-not-found.v-1 > *:not(:first-child) {
  margin-top: 15px;
}

/*** DIAGRAN ***/
.element-diagram {
  width: 100%;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  column-gap: 20px;
  -webkit-column-gap: 20px;
  padding: 8px;
  position: relative;
}

.template-link:hover,
.element-diagram:not(.unit-loading).active,
.element-diagram:not(.unit-loading):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.device-mobile .template-link:hover,
.device-mobile .element-diagram:not(.unit-loading):hover {
  background-color: inherit;
}

.element-diagram > * {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.diagram-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  z-index: 1;
}

.template-link {
  position: relative;
  border-radius: 8px;
  padding: 4px 10px;
  padding-right: 4px;
}

.template-link .button-icon {
  position: relative;
  z-index: 2;
}

.template-link .diagram-title {
  padding: 2px 0;
}

.element-diagram button {
  position: relative;
  z-index: 2;
}

.diagram-star {
  margin-right: -10px;
}

.diagram-template {
  margin-right: -8px;
}

.diagram-info {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
}

.diagram-members {
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  column-gap: 5px;
  -webkit-column-gap: 5px;
}

/*** TABS ***/
.tabs-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  column-gap: 15px;
  -webkit-column-gap: 15px;
  align-items: center;
  -webkit-align-items: center;
}

.icon-separator {
  width: 4px;
  height: 4px;
  background-color: rgba(183, 183, 223, 0.5);
  border-radius: 50%;
  margin: 0 4px;
}

.icon-separator + .icon-separator {
  display: none;
}

.tabs-filter > .tab-link {
  display: block;
  text-decoration: none;
  padding: 2px;
  cursor: pointer;
}

.tabs-filter > .tab-link.active {
  font-weight: bold;
}

/*** TEXT ***/
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-1 {
  font-size: 13px;
  color: #303743;
  font-weight: 400;
}

.text-1 .hint {
  display: inline-block;
}

.text-2 {
  font-size: 16px;
  color: #303743;
  font-weight: 400;
}

.text-3 {
  color: rgba(5, 0, 34, 0.4);
  font-size: 12px;
  font-weight: 400;
}

.text-4 {
  color: #050022;
  font-size: 18px;
  font-weight: 700;
}

.text-5 {
  color: rgba(5, 0, 34, 0.5);
  font-size: 14px;
  font-weight: 400;
}

.text-5 span {
  color: #050022;
}

.text-6 {
  font-size: 16px;
  color: rgba(5, 0, 34, 0.85);
  font-weight: 400;
}

.text-7 {
  color: rgba(5, 0, 34, 0.75);
  font-size: 14px;
  font-weight: 400;
}

.text-8 {
  color: #050022;
  font-size: 16px;
  font-weight: 700;
}

.text-9 {
  color: #050022;
  font-size: 14px;
  font-weight: 400;
}

.text-10 {
  color: #050022;
  font-size: 20px;
  font-weight: 700;
}

.text-11 {
  color: rgba(5, 0, 34, 0.5);
  font-size: 14px;
  font-weight: 700;
}

/*** INPUT ***/

input,
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
}

::placeholder,
.text-placeholder {
  color: rgba(0, 0, 0, 0.15);
  opacity: 1;
}

[contenteditable]:empty:before {
  content: attr(placeholder);
  color: rgba(5, 0, 34, 0.2);
}

:-ms-input-placeholder,
.text-placeholder {
  color: rgba(5, 0, 34, 0.2);
}

::-ms-input-placeholder,
.text-placeholder {
  color: rgba(5, 0, 34, 0.2);
}

.input-1 {
  font-size: 16px;
  font-weight: 400;
  padding: 9px 12px;
  border-radius: 4px;
  background-color: rgba(183, 183, 223, 0.15);
}

.input-1 + .hint {
  top: 10px;
  right: 5px;
}

.input-1:focus {
  background-color: rgba(183, 183, 223, 0.3);
}

.input-2:focus {
  background-color: transparent;
}

.input-2 {
  font-size: 16px;
  font-weight: 400;
  padding: 5.5px 8px;
  border-radius: 4px;
  background-color: rgba(183, 183, 223, 0.15);
}

.input-2 + .hint {
  top: 7px;
  right: 5px;
}

.input-2:focus {
  background-color: rgba(183, 183, 223, 0.3);
}

.input-3 {
  font-size: 16px;
  font-weight: 400;
}

.input-3 + .hint {
  top: 2px;
  right: 0;
}

.input-4 {
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  padding: 4px 5px;
  border-radius: 4px;
}

.input-4.error {
  padding-right: 20px;
}

.input-4:hover,
.input-4:focus {
  background-color: rgba(183, 183, 223, 0.15);
}

.input-5 {
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding: 5.5px 8px;
  border-radius: 4px;
}

.input-5.error {
  padding-right: 20px;
}

.input-5:hover,
.input-5:focus {
  background-color: rgba(183, 183, 223, 0.15);
}

.input-icon {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  column-gap: 3px;
  -webkit-column-gap: 3px;
}

.input-icon .icon {
  position: absolute;
  top: 50%;
  pointer-events: none;
}

.input-icon input {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  width: auto;
  min-width: auto;
  max-width: auto;
  border-radius: 4px;
}

.input-icon.v-1 input {
  font-size: 16px;
  font-weight: 700;
  height: 34px;
  padding-left: 32px;
  padding-right: 12px;
  background-color: transparent;
}

.input-icon.v-1 input:focus,
.input-icon.v-1 input:hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.input-icon.v-1 .icon {
  width: 26px;
  height: 26px;
  left: 4px;
  margin-top: -13px;
}

.input-option {
  background-color: rgba(183, 183, 223, 0.15);
  border-radius: 8px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  padding: 0 2px;
}

.input-option > * {
  position: relative;
  width: auto;
}

.input-option > *:not(:first-child)::before {
  content: '';
  position: absolute;
  left: -7px;
  width: 2px;
  pointer-events: none;
  height: 20px;
  background-color: rgba(183, 183, 223, 0.35);
  top: 50%;
  margin-top: -10px;
}

/*** HEADING ***/

h1 {
  font-size: 18px;
  font-weight: 700;
}

.heading {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.heading::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 2px;
  z-index: 1;
  margin-top: -1px;
}

.heading.v-1::before,
.heading.v-2::before {
  background-color: rgba(183, 183, 223, 0.15);
}

.heading-block {
  position: relative;
  z-index: 2;
}

.heading.v-1 .heading-block {
  margin: auto;
}

.heading.v-1 .heading-block,
.heading.v-2 .heading-block {
  background-color: var(--background-color);
}

.heading.v-1 .heading-block {
  padding: 0 5px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #C5C5D8;
}

.heading.v-1 .heading-block span {
  color: #303743;
}

.heading.v-2 .heading-block {
  padding-right: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #303743;
}


/*** BUTTON ***/
.button-disabled {
  opacity: 0.45;
  pointer-events: none;
}

header .button-disabled {
  opacity: 0.2;
}

button:active {
  color: inherit;
}

.button-text.v-1 {
  font-size: 14px;
  font-weight: 400;
  color: #050022;
  text-decoration: none;
}

.button-text.v-1:hover {
  text-decoration: underline;
}

.button-text.v-0 {
  color: inherit;
  font-weight: 700;
  text-decoration: none;
}

.button-text.v-0:not([disabled]):hover {
  text-decoration: underline;
}

.button-text.v-2 {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: #050022;
}

.button-text.v-2:hover {
  text-decoration: underline;
}

.button-text.v-3 {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 8px;
  color: rgba(5, 0, 34, 0.7);
  margin: 0 5px;
  border-radius: 2px;
  text-decoration: none;
}

.button-text.v-3:hover {
  text-decoration: none;
  background-color: rgba(183, 183, 223, 0.15);
}

.button-text.v-3.active {
  font-weight: 700;
  color: #050022;
}

.button-text.v-4 {
  font-size: 16px;
  font-weight: 700;
  color: #050022;
  border-radius: 4px;
  background-color: rgba(183, 183, 223, 0.15);
  text-decoration: none;
  padding: 9px 11px;
}

.button-text.v-4:hover {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-text.v-5 {
  font-size: 14px;
  font-weight: 700;
  color: #FF045C;
  border-radius: 4px;
  text-decoration: none;
  padding: 7px 8px;
}

.button-text.v-5[disabled] {
  color: rgba(180, 180, 199, 0.8);
  cursor: default;
}

.button-text.v-5:not([disabled]):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-text.v-6 {
  font-size: 13px;
  font-weight: 700;
  color: #050022;
  border-radius: 4px;
  background-color: rgba(183, 183, 223, 0.15);
  text-decoration: none;
  padding: 8px 10px;
}

.button-text.v-6:disabled {
  background-color: rgba(183, 183, 223, 0.1);
  color: rgba(5, 0, 34, 0.25);
  pointer-events: none;
}

.button-text.v-6:hover {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-text.v-7 {
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #EE2757;
  text-decoration: none;
  padding: 9px 11px;
}

.button-text.v-7:hover {
  background-color: #D71F4B;
}

.button-text.v-8 {
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF;
  border-radius: 4px;
  text-decoration: none;
  padding: 7px 10px;
  background-color: #303743;
  border: 1px solid transparent;
}

.button-text.v-8:hover,
.button-text.v-8:active {
  background-color: #1e232c;
}

.button-text.v-9 {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-fade);
  border-radius: 4px;
  text-decoration: none;
  padding: 7px 8px;
}

.button-text.v-9[disabled] {
  color: rgba(180, 180, 199, 0.8);
  cursor: default;
}

.button-text.v-9:not([disabled]):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-text.v-10 {
  font-size: 15px;
  font-weight: 400;
  color: rgba(5, 0, 34, 0.3);
  border-radius: 4px;
  text-decoration: none;
  padding: 10px 8px;
  width: 100%;
  text-align: center;
}

.button-text.v-10[disabled] {
  color: rgba(180, 180, 199, 0.8);
  cursor: default;
}

.button-text.v-10:not([disabled]):hover {
  background-color: rgba(183, 183, 223, 0.15);
  color: rgba(5, 0, 34, 0.7);
}

.button-text.v-11 {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-fade);
  text-decoration: none;
}

.button-text.v-11:hover {
  text-decoration: underline;
}

.button-text.v-12 {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-fade);
  text-decoration: none;
}

.button-text.v-12:hover {
  text-decoration: underline;
}

.button-text.v-13 {
  font-weight: 700;
  text-decoration: none;
}

.button-text.v-13:hover {
  text-decoration: underline;
}

.button-icon {
  border-radius: 4px;
}

.button-icon.disabled {
  cursor: default;
  opacity: 0.2;
}

.button-icon * {
  pointer-events: none;
}

.button-icon.v-1 {
  min-width: 34px;
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.button-icon.v-1:disabled {
  cursor: default;
}

.button-icon.v-1.active,
.button-icon.v-1:not(:disabled):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-icon.v-1.active:not(:disabled):hover  {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-icon.v-1 img {
  display: block;
  margin: auto;
}

.button-icon.v-2 {
  min-width: 31px;
  width: 31px;
  height: 31px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.button-icon.v-2.active,
.button-icon.v-2:hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-icon.v-2 img {
  display: block;
  margin: auto;
}

.button-icon.v-3 {
  min-width: 26px;
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(183, 183, 223, 0.15);
}

.button-icon.v-3:disabled {
  cursor: default;
}

.button-icon.v-3.active,
.button-icon.v-3:not(:disabled):hover {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-icon.v-3 img {
  display: block;
  margin: auto;
}

.button-icon.v-4 {
  min-width: 34px;
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  border-radius: 50%;
}

.button-icon.v-4:disabled {
  cursor: default;
}

.button-icon.v-4.active,
.button-icon.v-4:not(:disabled):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-icon.v-4.active:not(:disabled):hover  {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-icon.v-4 img {
  display: block;
  margin: auto;
}

.button-icon.v-5 {
  min-width: 28px;
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  border-radius: 50%;
  font-size: 14px;
  color: var(--text-color-fade);
}

.button-icon.v-5:disabled {
  cursor: default;
}

.button-icon.v-5.active,
.button-icon.v-5:not(:disabled):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-icon.v-5.active:not(:disabled):hover  {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-icon.v-5 img {
  display: block;
  margin: auto;
}

.button-text-icon {
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  text-decoration: none;
}

.button-text-icon.v-1.active,
.button-text-icon.v-1:hover {
  text-decoration: underline;
}

.button-text-icon.v-1 {
  color: #303743;
  font-size: 14px;
  font-weight: 700;
}

.button-text-icon.v-1 .text {
  padding: 0 10px;
}

.button-text-icon.v-2 {
  color: #303743;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  background-color: rgba(183, 183, 223, 0.15);
  border: 1px solid transparent;
  padding: 6px;
}

.button-text-icon.v-2 .text {
  padding-right: 8px;
  padding-left: 2px;
}

.button-text-icon.v-2.active,
.button-text-icon.v-2:hover {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-text-icon.v-3 {
  height: 34px;
  border-radius: 4px;
}

.button-text-icon.v-3:disabled {
  cursor: default;
}

.button-text-icon.v-3.active,
.button-text-icon.v-3:not(:disabled):hover {
  background-color: rgba(183, 183, 223, 0.15);
}

.button-text-icon.v-3.active:not(:disabled):hover  {
  background-color: rgba(183, 183, 223, 0.25);
}

.button-text-icon.v-3 img {
  display: block;
  margin: auto;
  margin-left: 2px;
}

.button-text-icon.v-3 .text {
  white-space: nowrap;
  padding-right: 8px;
  font-size: 14px;
}

.button-dark {
  border-radius: 4px;
  background-color: #303743;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
}

.button-dark.active,
.button-dark:hover {
  background-color: #1e232c;
  color: #fff;
}

.button-dark.v-1 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
}

.button-dark.v-2 {
  font-size: 14px;
  font-weight: 700;
  padding: 7px 10px;
  border-radius: 4px;
  border: 1px solid rgba(202, 197, 244, 0.15);
}

.button-red {
  border-radius: 4px;
  background-color: #EE2757;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
}

.button-red.active,
.button-red:hover {
  background-color: #D71F4B;
  color: #fff;
}

.button-red.v-1 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
}

.button-avatar {
  font-weight: 700;
  vertical-align: middle;
  color: #fff;
  outline: none;
  border: 1px solid transparent;
  border-radius: 100%;
  text-align: center;
}

.button-avatar.unknown {
  color: #050022;
  background-color: rgba(183, 183, 223, 0.15);
}

.button-avatar:active {
  color: #fff;
}

.button-avatar.unknown:active {
  color: #050022;
}

.button-avatar.v-1 {
  min-width: 28px;
  font-size: 15px;
  height: 28px;
  line-height: 26px;
}

.button-avatar.v-2 {
  min-width: 36px;
  font-size: 19px;
  height: 36px;
  line-height: 34px;
}

.button-avatar.v-3 {
  min-width: 40px;
  width: 40px;
  height: 40px;
  line-height: 39px;
  font-size: 20px;
}

.avatar-me,
.avatar-me:active {
  color: rgba(63, 58, 102, 0.25);
}

.button-select {
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  text-decoration: none;
  border-radius: 4px;
  padding: 7px 6px;
  padding-left: 10px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  white-space: nowrap;
  max-width: 100%;
  
  font-size: 14px;
  font-weight: 400;
}

.button-select.v-1 {
  font-size: 16px;
  font-weight: 700;
  padding: 5.5px 6px;
}

.button-select.bold {
  font-weight: 700;
}

.button-select:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  background: url('../icons/select-arrow.svg') no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}

.button-select.active:after,
.button-select:hover:after {
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.button-select.active,
.button-select:hover {
  width: 100%;
  background-color: rgba(183, 183, 223, 0.15);
}

/*** PROFILE ***/
.profile-popup {
  background-color: #fff;
  border: 1px solid rgba(183, 183, 223, 0.2);
  border-radius: 14px;
  max-width: 315px;
}

.profile-info {
  padding: 15px 20px;
}

.profile-identity {
  overflow: hidden;
  padding-right: 10px;
}

.profile-menu {
  padding-top: 6px;
  padding-bottom: 10px;
  border-top: 1px solid rgba(183, 183, 223, 0.2);
}

/*** DROPDOWN ***/
.element-dropdown {
  background-color: #303743;
  border-radius: 8px;
  position: relative;
  max-width: 265px;
  padding: 6px 0;
}

.element-menu > .scroll-wrap > div {
  margin-bottom: 0 !important;
  padding: 6px 0;
}

.element-dropdown > .element-menu > .scroll-wrap {
  margin-top: -6px;
  margin-bottom: -6px;
  border-radius: 8px;
}

.element-dropdown > .element-menu > .scroll-wrap > div {
  padding: 6px 0;
}

.element-dropdown::before {
  content: '';
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  margin-left: -8px;
  margin-left: calc(var(--left) * -1 - 8px);
  display: block;
  z-index: 1;
  left: 50%;
}

.element-dropdown.bottom::before {
  border-bottom: 6px solid #303743;
  margin-bottom: -1px;
  bottom: 100%;
}

/*** SHARE ***/
.share-title {
  padding: 20px;
}

.share-title span {
  font-weight: 400;
}

.search-title {
  padding: 20px;
  max-width: 440px;
}

.share-form {
  padding: 4px 20px;
}

.search-form {
  padding: 4px 20px;
  min-height: 44px;
  max-width: 440px;
}

.share-members {
  padding: 15px 20px;
  white-space: nowrap;
}

.share-member .member-name {
  padding: 0 8px;
}
.share-member .member-role {
  line-height: 34px;
  margin-top: -1px;
  margin-bottom: 1px;
}

.share-member .member-role:not(:last-child) {
  margin-right: 10px;
}

.share-link {
  padding: 15px 20px;
}

.share-to-element {
  padding: 0 8px;
}

/*** DIAGRAM ***/
.diagram-type {
  font-size: 18px;
  font-weight: 700;
  color: #B8B8C5;
  white-space: nowrap;
  margin-right: -20px;
}

.diagram-name {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

.section-left .diagram-name {
  margin-right: -5px;
}

.diagram-name + .hint {
  margin-left: -10px;
}

.diagram-name + .hint {
  margin-left: -38px;
  margin-right: 2px;
  width: 20px;
}

.diagram-name h1 {
  padding: 4px 6px;
  display: block;
}

.input-frame {
  position: absolute !important;
  pointer-events: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  visibility: hidden !important;
}

.input-frame:not(.limited) {
  white-space: pre !important;
}

.diagram-name input {
  max-width: 100%;
  margin-right: -1.5px;
}

.element-skeleton {
  background-color: rgba(183, 183, 223, 0.5);
  border-radius: 4px;
}

.element-skeleton.round {
  border-radius: 50%;
}


/*** ALERT ***/
.layout-alert {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.layout-alert.fixed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
}

.layout-alert.top {
  padding: 80px 20px;
}

.layout-alert > * {
  margin: auto;
}

.layout-alert.top > * {
  margin: 0 auto;
}

/*** ALERT ***/

.element-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  z-index: 300;
  height: 0;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;

  visibility: hidden;
  opacity: 0;
  
  transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  -ms-transition: opacity 0.5s, visibility 0.5s;
  -moz-transition: opacity 0.5s, visibility 0.5s;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
}

.element-alert.active {
  opacity: 1;
  visibility: visible;

  transition: opacity 0s, visibility 0s;
  -o-transition: opacity 0s, visibility 0s;
  -ms-transition: opacity 0s, visibility 0s;
  -moz-transition: opacity 0s, visibility 0s;
  -webkit-transition: opacity 0s, visibility 0s;
}

.element-alert > * {
  margin: auto;
  margin-bottom: 5px;
}

.element-alert > *:last-child {
  margin-bottom: 35px;
}

.element-alert-message {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
  padding: 9px 15px;
  max-width: 385px;
  cursor: pointer;
}



.element-alert-message.default {
  color: #fff;
  background-color: #303743;
}



.element-alert-message.error {
  color: #fff;
  background-color: #EC5378;
}

.button-text[data-frame] {
  text-align: center;
}

.button-text[data-frame]:after {
  content: attr(data-frame);
  display: block;
  height: 0;
  line-height: 0;
  font-weight: 700;
  letter-spacing: 0.15px;
  opacity: 0;
}

.beta-indicator {
  padding: 5px 15px;
  padding-right: 20px;
  padding-top: 8px;
  color: var(--text-color-fade);
  font-size: 12px;
  white-space: nowrap;
}

.beta-indicator span {
  color: #fff;
  display: inline-block;
  font-size: 9px;
  margin-right: 8px;
  text-transform: uppercase;
  font-weight: 900;
  background-color: #050022;
  padding: 4px 6px;
  padding-right: 5px;
  border-radius: 4px;
  letter-spacing: 1px;
  box-shadow: 0 2px 6px -2px rgba(5, 0, 34, 0.75);
  vertical-align: middle;
  margin-bottom: 2px;
}

/*** QR Code ***/
.qr-code > svg {
  border-radius: 6px;
  margin: 0 auto;
  display: block;
  width: 256px;
  height: 256px;
}

.qr-code {
  margin-bottom: 40px;
  height: 256px;
}


/*** Embed ***/
.field-code {
  min-height: 120px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  
  resize: none;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.94em;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.share-embed {
  padding: 15px 20px;
}

.share-embed > div:not(:first-child) {
  margin-top: 20px;
}

.share-embed-button {
  margin-right: 8px;
}