.layout-template.device-mobile header .section-wrap {
  column-gap: 15px;
  -webkit-column-gap: 15px;
}

.layout-template.device-mobile .button-icon:hover,
.layout-template.device-mobile .button-text:hover,
.layout-template.device-mobile .button-text-icon:hover,
.layout-template.device-mobile .button-dark:hover,
.layout-template.device-mobile .button-select:hover,
.layout-template.device-mobile .button:hover {
  background-color: inherit;
}

.layout-template.device-mobile .icon-template {
  margin-right: -8px;
}

.section-center .diagram-name input {
  max-width: calc(100vw - 242px) !important;
}

.layout-template.device-mobile header .hint .info {
  display: none;
}

.layout-template:not(.device-mobile) > header .group-colaborators-list {
  display: none;
}

@media (max-width: 1240px) {
  header .section-left .button-text-icon .text {
    display: none;
  }

  header .button-text-icon img {
    max-width: 100px;
  }

  .layout-template:not(.device-mobile) > header .section-right > .section-wrap > .group-colaborators {
    display: none;
  }

  .layout-template:not(.device-mobile) > header .group-colaborators-list {
    display: block;
  }
}

@media (max-width: 1040px) {
  .layout-main header .section-left,
  .layout-main header .section-right {
    width: calc(50% - 270px);
    flex-grow: 1;
    -webkit-flex-grow: 1;
  }
  .layout-main header .section-center {
    flex-grow: unset;
    -webkit-flex-grow: unset;
    width: 100%;
  }
  header .section-left .button-text-icon {
    margin-right: -10px;
  }
}

@media (max-width: 940px) {
  .layout-main header .section-left > .section-wrap > h1 {
    display: none;
  }
}

@media (max-width: 800px) {
  .layout-main header .section-left,
  .layout-main header .section-right {
    width: auto;
    max-width: 200px;
  }

  .layout-main header .section-center .hint .info {
    display: none;
  }

  .layout-template.device-mobile header .section-center .section-wrap {
    min-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .layout-template header .section-center {
    margin-top: -10px;
    margin-bottom: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .layout-template header .section-center .section-wrap {
    min-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .layout-template header .section-center.with-borders .section-wrap {
    width: max-content;
  }

  .layout-template header .section-left {
    padding-right: 10px;
  }

  .layout-template header .section-right {
    padding-left: 10px;
  }

  .section-center .popup-item {
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    margin-left: 24px;
  }

  .window-diagram > header {
    height: 55px;
  }
}

@media (max-width: 600px) {
  body .layout-template .input-option .button-text:hover {
    background-color: initial;
  }

  .diagram-members {
    display: none;
  }

  header h1 {
    display: none;
  }

  .input-icon input {
    width: 100%;
  }

  .input-option-wrap {
    width: 100%;
  }

  .share-embed > .group-line {
    display: block;
  }

  .share-embed > .group-line > div:not(:first-child) {
    margin-top: 15px;
  }

  .input-option {
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
    flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  }

  .input-option > *:not(:first-child)::before {
    height: 1px;
    width: auto;
    top: 9px;
    left: 0;
    right: 0;
  }

  .input-option > * {
    text-align: center;
  }

  .item-session {
    display: block;
  }

  .session-control {
    text-align: center;
    margin-right: 0;
    margin-top: 10px;
  }

  .session-control button {
    width: 100%;
    text-align: center;
  }

  .element-configuration .width-input {
    max-width: 100%;
  }

  .layout-header-bottom {
    margin: -10px -15px;
    padding: 10px 15px;
    margin-top: 10px;
    overflow-x: auto;
  }

  .layout-diagram-wrap,
  .layout-settings-wrap {
    padding-top: 15px;
  }

  .tabs-filter {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .diagram-item img {
    max-width: 100px;
  }

  .search-title,
  .search-form {
    max-width: 100%;
  }
}

@media (max-width: 380px) {
  .layout-embed header .section-center {
    display: none;
  }

  .layout-embed header .section-left {
    width: calc(80px);
    flex-grow: 1;
  }

  .layout-embed header .section-right {
    width: auto;
  }
}