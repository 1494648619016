.element.outline > :global(.block-content) > .image {
  padding: 4px;
  border-radius: 10px;
  background-color: var(--background-color);
  border: 2px solid var(--color);
}

.element > :global(.block-content) > .image:global(.unit-loading),
.element > :global(.block-content) > .image:global(.unit-failed),
.element.outline > :global(.block-content) > .image:global(.unit-loading),
.element.outline > :global(.block-content) > .image:global(.unit-failed) {
  background-color: rgba(183, 183, 223, 0.5);
  border-color: transparent;
}

.element > :global(.block-content) > .image:global(.unit-loading) img,
.element > :global(.block-content) > .image:global(.unit-failed) img {
  visibility: hidden;
}

.element > :global(.block-content) > .image {
  height: 100%;
  width: 100%;

  border-radius: 6px;
  pointer-events: none;
  position: relative;
  z-index: 20;
}

.element > :global(.block-content) > .image > img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
