/*** SETTINGS ***/
.layout-settings-wrap {
  padding: 0 15px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.layout-settings {
  max-width: 580px;
  margin: 0 auto;
}

.settings-block {
  margin-top: 20px;
}

.settings-block + .heading {
  margin-top: 50px;
}

.element-configuration > *:not(:first-child) {
  margin-top: 5px;
}

.element-configuration:not(:first-child) {
  margin-top: 10px;
}

.group-line > .element-configuration:not(:first-child) {
  margin-top: 0;
}

.configuration-extra:not(:first-child) {
  margin-top: 35px;
}

/*** DIAGRAM ***/
.layout-diagram-wrap {
  padding: 0 15px;
  padding-top: 30px;
  padding-bottom: 140px;
}

.layout-diagram {
  max-width: 580px;
  margin: 0 auto;
}

.layout-diagram.v-2 {
  padding: 0 10px;
}

.element-not-found + .layout-diagram,
.layout-diagram + .layout-diagram {
  margin-top: 20px;
}

.layout-diagram.v-2 + .layout-diagram {
  margin-top: 10px;
}

/*** HEADER-CENTER ***/
.layout-header-center {
  width: 100%;
}

.layout-header-center > *:not(:first-child) {
  margin-top: 20px;
}

.layout-header-bottom {
  max-width: 580px;
  margin: 0 auto;
  margin-top: 20px;
}

/*** MAIN ***/
.logo-main {
  margin-right: -2px;
}

.logo-main img {
  width: 30px;
  height: 30px;
  min-width: 30px;
}

.layout-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
}

.layout-main .scrollarea-content {
  min-height: 100%;
  min-width: 100%;
  display: inline-block;
}

header h1,
.layout-main header button,
.layout-main header a {
  white-space: nowrap;
}

.layout-main header {
  background-color: var(--background-color);
  border-bottom: 1px solid rgba(183, 183, 223, 0.15);
  width: 100%;
  padding: 10px 15px;
  position: relative;
  z-index: 34;

  transition: border-color 0.5s;
  -o-transition: border-color 0.5s;
  -ms-transition: border-color 0.5s;
  -moz-transition: border-color 0.5s;
  -webkit-transition: border-color 0.5s;
}

.window-diagram header {
  border-color: transparent;
}

.layout-main .section-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.layout-main header button {
  white-space: nowrap;
  outline: none;
}

.layout-main main {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
}

.layout-main header .section-left,
.layout-main header .section-right {
  width: calc(50% - 290px);
}

.layout-main header .section-left {
  padding-right: 20px;
}

.layout-main header .section-right {
  padding-left: 20px;
}

.layout-main header .section-center {
  width: 580px;

  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.layout-main header .section-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  width: 100%;
  align-items: center;
  -webkit-align-items: center;
  min-height: 34px;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  column-gap: 20px;
  -webkit-column-gap: 20px;
}

.section-left .section-wrap {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

.section-center .section-wrap {
  justify-content: center;
  -webkit-justify-content: center;
  min-width: max-content !important;
}

.section-center .section-wrap > .group-icon {
  flex: 1;
}

.section-right .section-wrap {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

/*** SIGNING ***/

.layout-signing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
}

.layout-signing > div:first-child > div:first-child {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  padding: 15px;
}

.signing-wrap {
  margin: auto;
  width: 315px;
  padding: 20px 15px;
  position: relative;
  z-index: 1;
}

.signing-sidebar {
  padding: 30px 35px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
}

.sidebar-left {
  right: auto;
  left: 0;
}

.signing-wrap .icon-logo {
  text-align: center;
  margin-bottom: 20px;
}

.signing-wrap .icon-logo a,
.signing-wrap .icon-logo img  {
  display: inline-block;
}

.signing-sidebar .text-helper {
  margin-bottom: 2px;
}

.signing-container > *:not(:first-child) {
  margin-top: 25px;
}

.signing-container {
  line-height: 1.35;
}

.signing-container .extra-button {
  margin-top: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
}

.signing-container .area-field {
  margin: 0 -15px;
}

/*** FORM ***/

.width-input {
  max-width: 315px;
}

.layout-form .area-field > *:not(:first-child) {
  margin-top: 15px;
}

.group-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  align-items: center;
  -webkit-align-items: center;
  column-gap: 2px;
  -webkit-column-gap: 2px;
}

.group-line.v-1 {
  align-items: flex-start;
  -webkit-align-items: flex-start;
  margin: -4px 0;
}

.group-line.v-2 {
  column-gap: 10px;
  -webkit-column-gap: 10px;
}

.group-line.v-3 {
  align-items: start;
  -webkit-align-items: start;
}

.group-line.v-3 .group-item-max {
  align-self: center;
}

.group-line.v-4 {
  column-gap: 15px;
  -webkit-column-gap: 15px;
}

.group-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  column-gap: 5px;
  -webkit-column-gap: 5px;
}

.group-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  column-gap: 15px;
  -webkit-column-gap: 15px;
  align-items: center;
  -webkit-align-items: center;
}

.group-field.right {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.group-item-max {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  min-width: auto;
  max-width: auto;
  width: auto;
}

.element-dropdown .area-button {
  padding: 0 6px;
  padding-top: 6px;
}

.layout-form .area-button {
  margin-top: 25px;
}

.layout-form .area-button > *:not(:first-child) {
  margin-top: 25px;
}

.diagram-members .hint,
.diagram-owner .hint {
  cursor: default;
}

/*** SESSIONS ***/
.layout-sessions:not(:first-child) {
  margin-top: 20px;
}

.item-session {
  border-radius: 8px;
  border: 2px solid rgba(183, 183, 223, 0.2);
  column-gap: 15px;
  -webkit-column-gap: 15px;
  padding: 11px 15px;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.item-session:not(:first-child) {
  margin-top: 10px;
}

.session-control {
  white-space: nowrap;
  margin-right: -5px;
  text-align: right;
  min-width: 140px;
}

.info-legal {
  text-align: center;
  max-width: 235px;
  margin: auto;
  line-height: 1.5;
}


/*** EMBED ***/
.layout-embed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
}

.layout-embed header button,
.layout-embed header a {
  white-space: nowrap;
}

.layout-embed header {
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  width: 100%;
  padding: 12px 14px;
  position: relative;
  z-index: 34;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.layout-embed header button {
  white-space: nowrap;
  outline: none;
}

.layout-embed main {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
}

.layout-embed header .section-left,
.layout-embed header .section-right {
  width: calc(50% - 90px);
}

.layout-embed header .section-left {
  padding-right: 12px;
}

.layout-embed header .section-right {
  padding-left: 12px;
}

.layout-embed header .section-center {
  width: 180px;

  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.layout-embed header .section-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  width: 100%;
  align-items: center;
  -webkit-align-items: center;
  min-height: 34px;
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  column-gap: 14px;
  -webkit-column-gap: 14px;
}

.embed-title {
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
}

.embed-title h1 {
  font-size: 14px;
  display: block;
  display: inline;
}

.layout-embed.no-controls header .section-center {
  display: none;
}

.layout-embed.no-controls header .section-left {
  width: calc(80px);
  flex-grow: 1;
}

.layout-embed.no-controls header .section-right {
  width: auto;
}
