.info {
  border-radius: 8px;
  position: relative;
  color: #FFFFFF;
  font-size: 0;
}

.info::before {
  content: '';
  display: block;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  left: 0;
  margin-left: min(max(calc(var(--left, 50%) - 8px), 4px), calc(100% - 20px));
  z-index: 1;
}

.info.top::before {
  top: 100%;
  margin-top: -1px;
  border-top: 6px solid transparent;
}

.info.bottom::before {
  bottom: 100%;
  margin-bottom: -1px;
  border-bottom: 6px solid transparent;
}

.info > * {
  position: relative;
  z-index: 2;
}

.info.info-information {
  background-color: #3A3D4E;
}

.info.info-information::before {
  border-top-color: #3A3D4E;
  border-bottom-color: #3A3D4E;
}

.info.info-default {
  background-color: #303743;
}

.info.info-default::before {
  border-top-color: #303743;
  border-bottom-color: #303743;
}

.info.info-warning {
  background-color: #EC5378;
}

.info.info-warning::before {
  border-top-color: #EC5378;
  border-bottom-color: #EC5378;
}