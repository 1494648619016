.placing-right > :global(.block-content) { padding-left: 4px; }
.placing-left > :global(.block-content) { padding-right: 4px; }
.placing-top > :global(.block-content) { padding-bottom: 4px; }
.placing-bottom > :global(.block-content) { padding-top: 4px; }

:global(.block-cell > .diagram-element).element.inline > :global(.block-arrangement .cc),
:global(.block-cell > .diagram-element).element.inline > :global(.block-arrangement:before) {
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}

:global(.block-cell > .diagram-element).element.inline {
  margin: max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px));
}

.element > :global(.block-content) > .checkbox {
  position: absolute;
  top: unset;
  bottom: unset;
  right: unset;
  left: unset;
  pointer-events: all;
  cursor: pointer;
}

.placing-right > :global(.block-content) > .checkbox { left: 4px; }
.placing-left > :global(.block-content) > .checkbox { right: 4px; }
.placing-top > :global(.block-content) > .checkbox { bottom: 4px; }
.placing-bottom > :global(.block-content) > .checkbox { top: 4px; }

.placing-right.size-s > :global(.block-content):not(.empty) { padding-left: 24px; }
.placing-right.size-m > :global(.block-content):not(.empty) { padding-left: 28px; }
.placing-right.size-l > :global(.block-content):not(.empty) { padding-left: 33px; }

.placing-left.size-s > :global(.block-content):not(.empty) { padding-right: 24px; }
.placing-left.size-m > :global(.block-content):not(.empty) { padding-right: 28px; }
.placing-left.size-l > :global(.block-content):not(.empty) { padding-right: 33px; }

.placing-bottom.size-s > :global(.block-content):not(.empty) { padding-top: 24px; }
.placing-bottom.size-m > :global(.block-content):not(.empty) { padding-top: 28px; }
.placing-bottom.size-l > :global(.block-content):not(.empty) { padding-top: 33px; }

.placing-top.size-s > :global(.block-content):not(.empty) { padding-bottom: 24px; }
.placing-top.size-m > :global(.block-content):not(.empty) { padding-bottom: 28px; }
.placing-top.size-l > :global(.block-content):not(.empty) { padding-bottom: 33px; }

.element > :global(.block-content > .block-wrap) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
}

.size-s > :global(.block-content > .block-wrap) { min-height: 16px; }
.size-m > :global(.block-content > .block-wrap) { min-height: 20px; }
.size-l > :global(.block-content > .block-wrap) { min-height: 25px; }

.size-s > :global(.block-content) > .checkbox { width: 16px; height: 16px; }
.size-m > :global(.block-content) > .checkbox { width: 20px; height: 20px; }
.size-l > :global(.block-content) > .checkbox { width: 25px; height: 25px; }

.placing-left.size-s.align-center > :global(.block-content) > .checkbox,
.placing-right.size-s.align-center > :global(.block-content) > .checkbox { margin-top: -8px; }

.placing-left.size-m.align-center > :global(.block-content) > .checkbox,
.placing-right.size-m.align-center > :global(.block-content) > .checkbox { margin-top: -10px; }

.placing-left.size-l.align-center > :global(.block-content) > .checkbox,
.placing-right.size-l.align-center > :global(.block-content) > .checkbox { margin-top: -12.5px; }

.placing-top.size-s.align-center > :global(.block-content) > .checkbox,
.placing-bottom.size-s.align-center > :global(.block-content) > .checkbox { margin-left: -8px; }

.placing-top.size-m.align-center > :global(.block-content) > .checkbox,
.placing-bottom.size-m.align-center > :global(.block-content) > .checkbox { margin-left: -10px; }

.placing-top.size-l.align-center > :global(.block-content) > .checkbox,
.placing-bottom.size-l.align-center > :global(.block-content) > .checkbox { margin-left: -12.5px; }

.size-s > :global(.block-content) > .checkbox rect { stroke-width: 2px; }
.size-m > :global(.block-content) > .checkbox rect { stroke-width: 1.6px; }
.size-l > :global(.block-content) > .checkbox rect { stroke-width: 1.28px; }

.size-s > :global(.block-content) > .checkbox path { stroke-width: 2.5px; }
.size-m > :global(.block-content) > .checkbox path { stroke-width: 2.2px; }
.size-l > :global(.block-content) > .checkbox path { stroke-width: 2.1px; }

.empty {
  width: 32px;
  height: 32px;
}

.placing-right.size-s > .empty > .checkbox { left: 8px; }
.placing-right.size-m > .empty > .checkbox { left: 6px; }
.placing-right.size-l > .empty > .checkbox { left: 3.5px; }

.placing-left.size-s > .empty > .checkbox { right: 8px; }
.placing-left.size-m > .empty > .checkbox { right: 6px; }
.placing-left.size-l > .empty > .checkbox { right: 3.5px; }

.placing-top.size-s > .empty > .checkbox { bottom: 8px; }
.placing-top.size-m > .empty > .checkbox { bottom: 6px; }
.placing-top.size-l > .empty > .checkbox { bottom: 3.5px; }

.placing-bottom.size-s > .empty > .checkbox { top: 8px; }
.placing-bottom.size-m > .empty > .checkbox { top: 6px; }
.placing-bottom.size-l > .empty > .checkbox { top: 3.5px; }

.placing-right.align-top > :global(.block-content) > .checkbox,
.placing-left.align-top > :global(.block-content) > .checkbox { top: 2px; }

.placing-right.align-center > :global(.block-content) > .checkbox,
.placing-left.align-center > :global(.block-content) > .checkbox { top: 50%; }

.placing-right.align-bottom > :global(.block-content) > .checkbox,
.placing-left.align-bottom > :global(.block-content) > .checkbox { bottom: 2px; }

.placing-top.align-left > :global(.block-content) > .checkbox,
.placing-bottom.align-left > :global(.block-content) > .checkbox { left: 2px; }

.placing-top.align-center > :global(.block-content) > .checkbox,
.placing-bottom.align-center > :global(.block-content) > .checkbox { left: 50%; }

.placing-top.align-right > :global(.block-content) > .checkbox,
.placing-bottom.align-right > :global(.block-content) > .checkbox { right: 2px; }

.checkbox rect {
  stroke: var(--color-text, var(--color));
  fill: var(--color-text, var(--color));
  fill-opacity: 0;

  transition: fill-opacity 0.2s;
  -o-transition: fill-opacity 0.2s;
  -ms-transition: fill-opacity 0.2s;
  -moz-transition: fill-opacity 0.2s;
  -webkit-transition: fill-opacity 0.2s;
}

.checkbox:hover rect {
  fill-opacity: 0.2;
}

.checkbox.selected rect {
  fill-opacity: 1;
}

:global(.layout-main.element-dragging) .checkbox {
  pointer-events: none;
}

.checkbox path {
  stroke: var(--color-block, #ffffff);

  transform: none;
  -o-transform: none;
  -ms-transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  transform-origin: center;
  -o-transform-origin: center;
  -ms-transform-origin: center;
  -moz-transform-origin: center;
  -webkit-transform-origin: center;

  transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  -o-transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  -ms-transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  -moz-transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  -webkit-transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
}

.checkbox:not(.selected) path {
  visibility: hidden;
  opacity: 0;
  transform: scale(1.35);
  -o-transform: scale(1.35);
  -ms-transform: scale(1.35);
  -moz-transform: scale(1.35);
  -webkit-transform: scale(1.35);
}

.checkbox + :global(.block-wrap > .diagram-element > .block-render),
.checkbox + :global(.block-wrap > .diagram-element > .block-content) {
  transition: opacity 0.15s;
  -o-transition: opacity 0.15s;
  -ms-transition: opacity 0.15s;
  -moz-transition: opacity 0.15s;
  -webkit-transition: opacity 0.15s;
}

.checkbox.selected + :global(.block-wrap > .diagram-element > .block-render),
.checkbox.selected + :global(.block-wrap > .diagram-element > .block-content) {
  transition: opacity 0.35s;
  -o-transition: opacity 0.35s;
  -ms-transition: opacity 0.35s;
  -moz-transition: opacity 0.35s;
  -webkit-transition: opacity 0.35s;
}

.checkbox.selected + :global(.block-wrap > .diagram-element > .block-render),
.checkbox.selected + :global(.block-wrap > .diagram-element > .block-content) {
  opacity: 0.3;
}
