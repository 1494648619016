.element > :global(.block-content) {
  padding: 4px;
}

:global(.block-cell > .diagram-element).element.inline > :global(.block-arrangement .cc),
:global(.block-cell > .diagram-element).element.inline > :global(.block-arrangement:before) {
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}

:global(.block-cell > .diagram-element).element.inline {
  margin: max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px + 3px)) max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px));
}

.element > :global(.block-content > .block-render) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: unset;
  right: unset;
  border-radius: 2px;
  background-color: var(--color-text, var(--color));
}
.placing-right > :global(.block-content > .block-render) { left: 6.5px; }
.placing-left > :global(.block-content > .block-render) { right: 6.5px; }

.size-s > :global(.block-content) { min-height: 16px; }
.size-m > :global(.block-content) { min-height: 18px; }
.size-l > :global(.block-content) { min-height: 22px; }

.placing-right.size-s > :global(.block-content) { padding-left: 16px; }
.placing-right.size-m > :global(.block-content) { padding-left: 18px; }
.placing-right.size-l > :global(.block-content) { padding-left: 22px; }

.placing-left.size-s > :global(.block-content) { padding-right: 16px; }
.placing-left.size-m > :global(.block-content) { padding-right: 18px; }
.placing-left.size-l > :global(.block-content) { padding-right: 22px; }

.size-s > :global(.block-content > .block-render) { width: 3px; }
.size-m > :global(.block-content > .block-render) { width: 5px; }
.size-l > :global(.block-content > .block-render) { width: 7px; }

.placing-right.size-l > :global(.block-content > .block-render) { left: 7.5px; }
.placing-left.size-l > :global(.block-content > .block-render) { right: 7.5px; }

.empty {
  width: 32px;
  height: 32px;
}

.placing-right.size-s > .empty > :global(.block-render) { left: 14.5px; }
.placing-right.size-m > .empty > :global(.block-render) { left: 13.5px; }
.placing-right.size-l > .empty > :global(.block-render) { left: 12.5px; }

.placing-left.size-s > .empty > :global(.block-render) { right: 14.5px; }
.placing-left.size-m > .empty > :global(.block-render) { right: 13.5px; }
.placing-left.size-l > .empty > :global(.block-render) { right: 12.5px; }
