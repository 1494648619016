.element > :global(.block-content) {
  font-size: 16px;
}

.element > :global(.block-content) > img,
.element > :global(.block-content) > svg {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  pointer-events: none;
}

.image:global(.unit-loading),
.image:global(.unit-failed) {
  border-radius: 6px;
  background-color: rgba(183, 183, 223, 0.5);
  border-color: transparent;
}

.image:global(.unit-loading) > img,
.image:global(.unit-failed) > img,
.image:global(.unit-loading) > svg,
.image:global(.unit-failed) > svg {
  visibility: hidden;
}

.image > svg *[stroke="#050022"] { stroke: var(--color, #3A3D4E); }
.image > svg *[fill="#050022"] { fill: var(--color, #3A3D4E); }
.image > svg *[stroke="#E7E8EA"] { stroke: var(--background, #E7E8EA); }
.image > svg *[fill="#E7E8EA"] { fill: var(--background, #E7E8EA); }
