.element {
  --transparent: var(--background-color);
}

.transparent {
  --transparent: transparent;
}

.element > :global(.block-content) {
  min-width: 48px;
  min-height: 48px;
}

.element:global(.wrapping > .block-content) {
  min-width: 24px;
  min-height: 24px;
}

.element > .wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;

  min-width: max-content;
}

.wrap > :global(.block-wrap) {
  padding: 10px;
}

.hidden {
  visibility: hidden;
}