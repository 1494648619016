.icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.border {
  border: 1px solid rgba(183, 183, 223, 0.5);
}

.none {
  background: url('../../../assets/icons/no-color.svg') no-repeat center center  !important;
  background-size: contain !important;
}
