.color-dark { --background: #0e0738; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-yellow { --background: #e9b50e; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-orange { --background: #ff8446; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-red { --background: #f30c46; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-green { --background: #55b280; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-sky { --background: #618fd5; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-blue { --background: #486bd0; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-purple { --background: #a04ee2; --highlight: rgba(255, 255, 255, 0.2); --color: #fff; --color-fade: rgba(255, 255, 255, 0.45) }
.color-grey { --background: #eaeaf7; --highlight: rgba(167, 167, 198, 0.3); --color: #494469; --color-fade: rgba(73, 68, 105, 0.45) }

.size-s > :global(.block-content > .component-text) { font-size: 11px; }
.size-m > :global(.block-content > .component-text) { font-size: 15px; }
.size-l > :global(.block-content > .component-text) { font-size: 19px; }

.element :global(.block-content:not(.text-vertical) > .quill .ql-editor.ql-blank::after),
.element :global(.text-placeholder) {
  color: var(--color);
  opacity: 0.5;
}

.element {
  white-space: nowrap;
  color: var(--color);
}

.render {
  background-color: var(--background);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 6px;
}

.size-s > :global(.block-content) { padding: 10px 12px; }
.size-m > :global(.block-content) { padding: 12px 14px; }
.size-l > :global(.block-content) { padding: 14px 16px; }

.element > :global(.block-content) {
  counter-reset: code;
  width: max-content;
  font-family: "Courier New", Courier, monospace;
}

.element > :global(.block-content) p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  padding-left: calc(var(--line-counting) * 10px + 10px);
}

.element > :global(.block-content) p:before {
  counter-increment: code;
  content: counter(code);
  padding-right: 6px;
  text-align: right;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(var(--line-counting) * 10px + 4px);
  color: var(--color-fade);
}

.text,
.input :global(.ql-editor) {
  padding: 2px 4px;
}

:global(.text-vertical) > .text,
:global(.text-vertical) > .input :global(.ql-editor) {
  padding: 4px 2px;
}

.text,
.input {
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
