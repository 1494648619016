.wrap-block-great {
  padding: 20px;
}

.wrap-block-big {
  padding: 15px;
}

.wrap-block-normal {
  padding: 10px;
}

.wrap-block-small {
  padding: 5px;
}

.wrap-cut-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

.wrap-cut-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.wrap-text-big {
  padding: 8px 12px;
  padding-bottom: 9px;
}

.wrap-text-normal {
  padding: 5px 9px;
  padding-bottom: 6px;
}

.wrap-text-small {
  padding: 3px 7px;
  padding-bottom: 4px;
}

.wrap-text-short {
  padding: 3px 5px;
  padding-bottom: 4px;
}