.modal {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 280;
}

.modal-window {
  min-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
}

.modal .modal-back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(236, 236, 242, 0.5);
}

.modal .modal-wrap {
  position: relative;
  z-index: 2;
  margin: auto;
}

.modal.max-width .modal-wrap {
  width: 100%;
}

.modal ul,
.modal ol {
  margin: 0;
  padding-left: 18px;
}

.modal .modal-wrap .text-9 {
  line-height: 1.5;
}



.modal.v-1 .modal-wrap {
  background-color: #fff;
  border: 1px solid rgba(183, 183, 223, 0.2);
  border-radius: 14px;
  min-width: 440px;
  max-width: 585px;
}

.modal.v-1 .modal-wrap > *:not(:first-child) {
  border-top: 1px solid rgba(183, 183, 223, 0.2);
}

.modal.v-1.max-width .modal-wrap {
  max-width: 610px;
}



.modal.v-2 .modal-wrap,
.modal.v-3 .modal-wrap {
  background-color: #fff;
  border: 1px solid rgba(183, 183, 223, 0.2);
  border-radius: 14px;
  width: 335px;
  max-width: 335px;
  padding: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  row-gap: 15px;
  -webkit-row-gap: 15px;
}

.modal.v-3 .modal-wrap {
  width: 500px;
  max-width: 500px;
}

@media (max-width: 600px) {
  .modal .modal-wrap {
    width: 100% !important;
  }

  .modal.v-1 .modal-wrap {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}