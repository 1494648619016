.element {
  --transparent: var(--background-color);
}

:global(.layout-template:not(.element-dragging)) .frame {
  pointer-events: all;
  position: relative;
  z-index: 15;
}

.element > :global(.block-content > .block-wrap) {
  padding: 5px;
  padding-right: 20px;
}