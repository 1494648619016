.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: auto;
  position: relative;
}

.icon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: url('./icon.svg') no-repeat center center;
  background-size: contain;
}

.failed:after {
  content: '?';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  line-height: 16px;
  font-size: 16px;
  font-weight: 700;
  color: rgba(120, 120, 150, 0.7)
}