.scrollarea .scrollbar {
  border-radius: 10px;
}

.scrollarea:hover .scrollbar-container {
  opacity: 0.1 !important;
}

.scrollarea .scrollbar-container.horizontal .scrollbar {
  background-color: var(--text-color) !important;
}

.scrollarea:hover .scrollbar-container.active,
.scrollarea:hover .scrollbar-container:hover {
  background:rgba(183, 183, 223, 0.3) !important;
  opacity: 0.3 !important;
}

.scrollarea.dragging {
  cursor: grabbing;
}

.scrollarea.dragging:after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  cursor: grabbing;
  display: block;
}

.window-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.window-wrap .window-container {
  display: inline-block;
  margin: auto;
}

.app-logo, .app-menu {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 34;
}

.app-menu {
  z-index: 31;
  left: 62px;
}

.app-menu > .layout-flex {
  min-height: 36px;
}

.app-view {
  outline: none;
}

.app-view:not(.mode-editor) .app-element-wrap:not(.is-selected) .app-element:hover {
  box-shadow: 0px 0px 0px 3px rgba(183, 183, 223, 0.4);
  -moz-box-shadow: 0px 0px 0px 3px rgba(183, 183, 223, 0.4);
  -webkit-box-shadow: 0px 0px 0px 3px rgba(183, 183, 223, 0.4);
}

.mode-editor .app-element-wrap:not(.is-selected) .app-element:hover {
  outline: none;
  cursor: default;
}

.query-not-found {
  text-align: center;
  color: rgba(5, 0, 34, 0.25);
  padding: 10px 0;
}

ul.simple-list {
  margin: 5px 0;
  padding: 0;
  padding-left: 20px;
  list-style: inherit;
}

@keyframes animation-scale {
  0% {
    transform: scale(0.85);
    -o-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -webkit-transform: scale(0.85);
  }

  100% {
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.animation-scale {
  animation: animation-scale 0.2s cubic-bezier(0.3, 1.56, 0.64, 1);
  -moz-animation: animation-scale 0.2s cubic-bezier(0.3, 1.56, 0.64, 1);
  -webkit-animation: animation-scale 0.2s cubic-bezier(0.3, 1.56, 0.64, 1);
}

@keyframes animation-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animation-fade {
  animation: animation-fade 0.15s ease-out;
  -moz-animation: animation-fade 0.15s ease-out;
  -webkit-animation: animation-fade 0.15s ease-out;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.scroll-wrap > div:first-child {
  overflow-x: hidden !important;
}

.scroll-wrap > div:not(:first-child) > div {
  background-color: rgba(183, 183, 223, 0.3) !important;
}

.scroll-wrap > div:not(:first-child) > div:hover {
  background-color: rgba(183, 183, 223, 0.35) !important;
}

.scroll-wrap > div:nth-child(2) {
  display: none;
}

body.element-dropping:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: rgba(236, 236, 242, 0.5) url('../icons/plus-thin.svg') no-repeat center center;
  background-size: 100px;
}

iframe[src*="feedback.fish"] {
  transform: none !important;
  top: auto !important;
  left: auto !important;
  right: 20px !important;
  bottom: 20px !important;
}

::-moz-selection {
  color: inherit;
  background-color: rgba(0, 170, 255, 0.25);
}

::selection {
  color: inherit;
  background-color: rgba(0, 170, 255, 0.25);
}
