@font-face {
  font-family: "PT Sans";
  src: url("../fonts/PTSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/PTSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/PTSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/PTSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

body {
  --text-color: #050022;
  --text-color-fade: rgba(5, 0, 34, 0.7);
  --background-color: #FFFFFF;
  --transparent: transparent;

  color: var(--text-color);
  background-color: var(--background-color);

  font-family: "PT Sans","Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  font-style: normal;

  overflow: hidden;

  touch-action: none;
  -o-touch-action: none;
  -ms-touch-action: none;
  -moz-touch-action: none;
  -webkit-touch-action: none;

  text-size-adjust: none;
  -o-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.text-10, h4 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16, h3 {
  font-size: 16px;
}

.text-20, h2 {
  font-size: 20px;
}

.icon {
  display: block;
  width: auto;
}

.icon.great {
  height: 64px;
}

.icon.greater {
  height: 48px;
}

.icon.big {
  height: 36px;
}

.icon.bigger {
  height: 30px;
}

.icon.normal {
  height: 26px;
}

.icon.small {
  height: 22px;
}

h2 {
  font-weight: bold;
}

h3 {
  font-weight: bold;
  text-transform: uppercase;
}

h4 {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* input,
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: none;
  background-color: rgba(183, 183, 223, 0.15);
  border: 1px solid rgba(183, 183, 223, 0.15);
  border-radius: 8px;
  outline: none;
  margin: 0;
} */

input.input-disguised,
textarea.input-disguised {
  background-color: transparent;
  border-color: transparent;
}

input.input-clear,
textarea.input-clear {
  background-color: transparent;
  border-color: transparent;
}

@keyframes unit-loading {
  0% { opacity: 0.3;}
  50% { opacity: 0.6; }
  100% { opacity: 0.3; }
}

.unit-loading {
  animation-name: unit-loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.unit-loading:not(.block-content) {
  pointer-events: none;
}

input.input-disguised:not([disabled]):hover,
textarea.input-disguised:not([disabled]):hover {
  background-color: rgba(183, 183, 223, 0.15);
  border-color: transparent;
}

/* input:focus,
input.input-disguised:not([disabled]):focus,
textarea:focus,
textarea.input-disguised:not([disabled]):focus {
  background-color: rgba(183, 183, 223, 0.15);
  border-color: transparent;
} */

/* input:focus,
textarea:focus {
  border-color: rgba(183, 183, 223, 0.75);
} */

input.input-error, 
input.input-error:not([disabled]):hover, 
input.input-error:focus, 
input.input-disguised.input-error:not([disabled]):hover, 
input.input-disguised.input-error:focus,
textarea.input-error, 
textarea.input-error:not([disabled]):hover, 
textarea.input-error:focus, 
textarea.input-disguised.input-error:not([disabled]):hover, 
textarea.input-disguised.input-error:focus  {
  border-color: #EC5378;
  box-shadow: 0px 0px 4px rgba(243, 27, 79, 0.25);
  -moz-box-shadow: 0px 0px 4px rgba(243, 27, 79, 0.25);
  -webkit-box-shadow: 0px 0px 4px rgba(243, 27, 79, 0.25);
}

input.input-clear:not([disabled]):hover,
input.input-clear:focus,
textarea.input-clear:not([disabled]):hover,
textarea.input-clear:focus {
  background-color: transparent;
  border-color: transparent;
}

input,
.text-overflow {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.input-hint-wrap {
  position: relative;
  z-index: 15;
}

.input-hint-wrap.fit-content {
  width: fit-content;
  width: -o-fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  min-width: 100%;
}

.diagram-block .input-hint-wrap textarea {
  resize: none;
}

.input-hint-wrap:hover {
  z-index: 16;
}

.input-hint-wrap .hint {
  position: absolute;
  right: 0;
  top: 5px;
}

.diagram-canvas .input-hint-wrap .hint {
  right: -5px;
}

@keyframes logo-line-loading {
  0% { stroke-dashoffset: 0px; }
  100% { stroke-dashoffset: 105px; }
}

.app-loader-line {
  stroke-dasharray: 57px 48px;
  
  animation-name: logo-line-loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#app-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 3;
}

#tooltip-root {
  position: relative;
  z-index: 777;
  pointer-events: none;
}

.block-hidden {
  display: none;
}