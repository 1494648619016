.element {
  --color-text: #3A3D4E;
  --transparent: var(--background-color);

  color: var(--color-text);
  min-width: 48px;
  white-space: nowrap;
}

.element :global(.block-content) { padding: 4px; }
.element :global(.hint) { right: 0 !important; }
.element p { margin: 0 !important; }
.element :global(.input-hint-wrap) {
  min-width: 100%;
  width: fit-content;
}

.text p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text,
.input :global(.ql-editor) { padding: 5px 12px; }
.error :global(.ql-editor) { padding-right: 20px; }

.detail { color: rgba(58, 61, 78, 0.4); }
.element:not(.detailed) .detail { display: none; }

.collapsed {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: var(--color);
  border-radius: 50%;
}

.collapsed.l, .collapsed.r { top: 50%; margin-top: -3px; }
.collapsed.t, .collapsed.b { left: 50%; margin-left: -3px; }

.collapsed.t { top: -11px; }
.collapsed.l { left: -11px; }
.collapsed.r { right: -11px; }
.collapsed.b { bottom: -11px; }

/* Stereotype */

.stereotype {
  font-size: 12px;
  text-align: center;
  margin-bottom: -10px;
  opacity: 0.7;
}

.stereotype .text,
.stereotype .input :global(.ql-editor) {
  padding: 2px 12px;
}

.stereotype br { display: none; }
.stereotype :global(.ql-blank) br { display: block; }

.stereotype p:after { content: '»'; }
.stereotype p:before { content: '«'; }

.stereotype :global(.ql-blank) p:after { display: none; }
.stereotype :global(.ql-blank) p:before { content: ' '; }

/* Name */

.name {
  font-size: 16px;
  text-align: center;
}

.name:not(:last-child) {
  padding-bottom: 3px;
}

/* Attributes */

.attributes {
  font-size: 16px;
  padding-top: 3px;
}

.attributes:not(:last-child) { padding-bottom: 3px;}

.attributes .text,
.attributes .input :global(.ql-editor) {
  padding-left: 8px;
  padding-right: 8px;
}

.attributes .text + .text,
.attributes .text + .input :global(.ql-editor) {
  padding-top: 1px;
}

/* Operations */

.operations {
  font-size: 16px;
  padding-top: 3px;
}

.operations:not(:last-child) { padding-bottom: 3px;}

.operations .text,
.operations .input :global(.ql-editor) {
  padding-left: 8px;
  padding-right: 8px;
}

.operations .text + .text,
.operations .text + .input :global(.ql-editor) {
  padding-top: 1px;
}
