.element > :global(.block-content):not(.vertical) {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
}

.element > :global(.block-content).vertical {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important;
}

.render {
  background-color: var(--color);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 3px;
}
