/* Color */
.color-none { --color: #3A3D4E; --background: transparent; --background-dark: hsl(0, 0%, 94%); --highlight: var(--color-highlight, rgba(58, 61, 78, 0.2)); }
.color-white { --color: #3A3D4E; --background: #FFFFFF; --background-dark: hsl(0, 0%, 94%); --highlight: var(--color-highlight, rgba(58, 61, 78, 0.2)); }
.color-dark { --color: #3A3D4E; --background: #E7E8EA; --background-dark: hsl(220, 7%, 85%); --highlight: var(--color-highlight, rgba(58, 61, 78, 0.2)); }
.color-yellow { --color: #FFCF35; --background: #FFF8E1; --background-dark: hsl(46, 100%, 87%); --highlight: var(--color-highlight, rgba(255, 207, 53, 0.25)); }
.color-orange { --color: #FF935C; --background: #FFEFE7; --background-dark: hsl(20, 100%, 87%); --highlight: var(--color-highlight, rgba(255, 147, 92, 0.25)); }
.color-red { --color: #FD3063; --background: #FFE0E8; --background-dark: hsl(345, 100%, 87%); --highlight: var(--color-highlight, rgba(253, 48, 99, 0.2)); }
.color-green { --color: #68CC95; --background: #E1F5EA; --background-dark: hsl(147, 50%, 85%); --highlight: var(--color-highlight, rgba(104, 204, 149, 0.3)); }
.color-sky { --color: #72A5F1; --background: #EAF2FD; --background-dark: hsl(215, 83%, 87%); --highlight: var(--color-highlight, rgba(114, 165, 241, 0.25)); }
.color-blue { --color: #5474D1; --background: #E5EAF8; --background-dark: hsl(224, 58%, 87%); --highlight: var(--color-highlight, rgba(84, 116, 209, 0.25)); }
.color-purple { --color: #AE72DE; --background: #F3EAFA; --background-dark: hsl(274, 62%, 87%); --highlight: var(--color-highlight, rgba(174, 114, 222, 0.2)); }
.color-grey { --color: #CACADE; --background: #F7F7FA; --background-dark: hsl(240, 23%, 90%); --highlight: var(--color-highlight, rgba(167, 167, 198, 0.25)); }

.text span[style*="background"],
.text strong[style*="background"],
.text b[style*="background"],
.text em[style*="background"],
.text s[style*="background"],
.text u[style*="background"] {
  background-color: var(--highlight) !important;
}

.size-s > :global(.block-content > .component-text) { font-size: 12px; }
.size-m > :global(.block-content > .component-text) { font-size: 16px; }
.size-l > :global(.block-content > .component-text) { font-size: 20px; }

.size-s > :global(.block-content > .component-text) h1 { font-size: 18px; }
.size-m > :global(.block-content > .component-text) h1 { font-size: 22px; }
.size-l > :global(.block-content > .component-text) h1 { font-size: 26px; }

.size-s > :global(.block-content > .component-text) h2 { font-size: 15px; }
.size-m > :global(.block-content > .component-text) h2 { font-size: 19px; }
.size-l > :global(.block-content > .component-text) h2 { font-size: 23px; }

.text a {
  background-color: rgba(60, 170, 245, 0.25) !important;
}

/* Render */
:global(.render-fill) { fill: var(--background); }
:global(.render-fill-dark) { fill: var(--background-dark); }
:global(.render-stroke) { stroke: var(--color); }
:global(.render-stroke-color) { stroke: var(--color); }
:global(.render-stroke-fill) { stroke: var(--color); fill: var(--color); }
:global(.render-fill-stroke) { fill: var(--color); }

:global(.render-opacity) { stroke-opacity: 0.1; }
.color-none :global(.render-opacity),
.color-white :global(.render-opacity),
.color-dark :global(.render-opacity),
.color-blue :global(.render-opacity),
.color-red :global(.render-opacity) { stroke-opacity: 0.07; }
.color-yellow :global(.render-opacity),
.color-grey :global(.render-opacity) { stroke-opacity: 0.15; }

.render-0 > :global(.block-render svg .render-fill),
.render-2 > :global(.block-render svg .render-fill),
.render-4 > :global(.block-render svg .render-fill),
.render-0 > svg :global(.render-fill),
.render-2 > svg :global(.render-fill),
.render-4 > svg :global(.render-fill),
.render-0 > :global(.block-render svg .render-fill-stroke),
.render-2 > :global(.block-render svg .render-fill-stroke),
.render-4 > :global(.block-render svg .render-fill-stroke),
.render-0 > svg :global(.render-fill-stroke),
.render-2 > svg :global(.render-fill-stroke),
.render-4 > svg :global(.render-fill-stroke) {
  fill: var(--transparent);
}

.render-1 > :global(.block-render .render-stroke),
.render-7 > :global(.block-render .render-stroke),
.render-9 > :global(.block-render .render-stroke) {
  stroke-dasharray: unset;
}

.render-7 > :global(.block-render .render-stroke) {
  stroke: var(--background);
}

.render-2 > :global(.block-render .render-stroke),
.render-3 > :global(.block-render .render-stroke) {
  stroke-dasharray: 1px 6px;
}

.render-4 > :global(.block-render .render-stroke),
.render-5 > :global(.block-render .render-stroke) {
  stroke-dasharray: 6px 8px;
}

.render-6 > :global(.block-render .render-stroke),
.render-9 > :global(.block-render .render-stroke) {
  stroke: var(--background-color);
}

.render-6 > :global(.block-render .render-fill),
.render-8 > :global(.block-render .render-fill) {
  fill: var(--color);
}

.render-6 > :global(.block-render .render-stroke-color),
.render-8 > :global(.block-render .render-stroke-color) {
  stroke: var(--background-color);
}

.render-6 > :global(.block-render .render-stroke-fill),
.render-8 > :global(.block-render .render-stroke-fill) {
  stroke: var(--background-color); fill: var(--background-color);
}

.render-6 > :global(.block-render .render-fill-stroke),
.render-8 > :global(.block-render .render-fill-stroke) {
  fill-opacity: 1;
}

.render-6.color-white > :global(.block-render .render-fill),
.render-8.color-white > :global(.block-render .render-fill) {
  fill: var(--background);
}

.render-6.color-white > :global(.block-render .render-stroke),
.render-8.color-white > :global(.block-render .render-stroke) {
  stroke: var(--color);
}

.render-6.color-white > :global(.block-render .render-stroke-fill),
.render-8.color-white > :global(.block-render .render-stroke-fill) {
  stroke: var(--color); fill: var(--color);
}

.render-6.color-none,
.render-6.color-dark,
.render-6.color-yellow,
.render-6.color-orange,
.render-6.color-red,
.render-6.color-green,
.render-6.color-sky,
.render-6.color-blue,
.render-6.color-purple,
.render-8.color-none,
.render-8.color-dark,
.render-8.color-yellow,
.render-8.color-orange,
.render-8.color-red,
.render-8.color-green,
.render-8.color-sky,
.render-8.color-blue,
.render-8.color-purple {
  --color-block: var(--color);
  --color-text: #fff;
  --color-solid: #fff;
  --color-highlight: rgba(255, 255, 255, 0.3);
}

.render-6.color-grey,
.render-8.color-grey {
  --color: #e8e8f1;
  --highlight: rgba(58, 61, 78, 0.14);
}

.opaque.color-none:not(.render-6):not(.render-8),
.opaque.color-dark:not(.render-6):not(.render-8),
.opaque.color-yellow:not(.render-6):not(.render-8),
.opaque.color-orange:not(.render-6):not(.render-8),
.opaque.color-red:not(.render-6):not(.render-8),
.opaque.color-green:not(.render-6):not(.render-8),
.opaque.color-sky:not(.render-6):not(.render-8),
.opaque.color-blue:not(.render-6):not(.render-8),
.opaque.color-purple:not(.render-6):not(.render-8),
.opaque.color-grey:not(.render-6):not(.render-8),
.render-1.color-none,
.render-1.color-dark,
.render-1.color-yellow,
.render-1.color-orange,
.render-1.color-red,
.render-1.color-green,
.render-1.color-sky,
.render-1.color-blue,
.render-1.color-purple,
.render-1.color-grey,
.render-7.color-none,
.render-7.color-dark,
.render-7.color-yellow,
.render-7.color-orange,
.render-7.color-red,
.render-7.color-green,
.render-7.color-sky,
.render-7.color-blue,
.render-7.color-purple,
.render-7.color-grey,
.render-9.color-none,
.render-9.color-dark,
.render-9.color-yellow,
.render-9.color-orange,
.render-9.color-red,
.render-9.color-green,
.render-9.color-sky,
.render-9.color-blue,
.render-9.color-purple,
.render-9.color-grey,
.render-3.color-none,
.render-3.color-dark,
.render-3.color-yellow,
.render-3.color-orange,
.render-3.color-red,
.render-3.color-green,
.render-3.color-sky,
.render-3.color-blue,
.render-3.color-purple,
.render-3.color-grey,
.render-5.color-none,
.render-5.color-dark,
.render-5.color-yellow,
.render-5.color-orange,
.render-5.color-red,
.render-5.color-green,
.render-5.color-sky,
.render-5.color-blue,
.render-5.color-purple,
.render-5.color-grey {
  --color-block: var(--null);
  --color-text: var(--null);
  --color-highlight: var(--null);
  --color-solid: var(--null);
}