.element {
  --transparent: transparent;
}

.element > :global(.block-content) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.element:not(.hidden) > :global(.block-content) {
  pointer-events: none;
}

.hidden > :global(.block-content) {
  pointer-events: all;
}

.element > :global(.block-content > .block-wrap) {
  height: max-content;
  pointer-events: all;
}

.element > :global(.block-arrangement ~ .block-content > .block-wrap),
:global(.layout-main.element-dragging) .element > :global(.block-content > .block-wrap) {
  pointer-events: none;
}

.title {
  background-color: #13a3;
}

.frame {
  pointer-events: none;
}

:global(.layout-main:not(.element-dragging) .diagram-canvas.mode-edit) .element:global(.focused) .frame {
  pointer-events: all;
  position: relative;
  z-index: 15;
}

:global(.diagram-canvas.mode-edit) .element:global(.selected) .frame {
  z-index: 30;
}

:global(.diagram-canvas.mode-edit) .element:global(.selected > .block-content) {
  z-index: 31 !important;
}

.disabled {
  pointer-events: none;
}

.placing-left,
.placing-right,
.placing-top,
.placing-top-left,
.placing-top-right,
.placing-left-top,
.placing-right-top {
  align-items: start;
  -webkit-align-items: start;
}

.placing-left,
.placing-right,
.placing-bottom,
.placing-bottom-left,
.placing-bottom-right,
.placing-left-bottom,
.placing-right-bottom {
  align-items: end;
  -webkit-align-items: end;
}

.placing-top,
.placing-bottom,
.placing-left,
.placing-left-top,
.placing-left-bottom,
.placing-top-left,
.placing-bottom-left {
  justify-content: start;
  -webkit-justify-content: start;
}

.placing-top,
.placing-bottom,
.placing-right,
.placing-right-top,
.placing-right-bottom,
.placing-top-right,
.placing-bottom-right {
  justify-content: end;
  -webkit-justify-content: end;
}

.placing-top > div,
.placing-bottom > div,
.placing-top-right > div,
.placing-bottom-right > div {
  margin-left: auto;
}

.placing-top > div,
.placing-bottom > div,
.placing-top-left > div,
.placing-bottom-left > div {
  margin-right: auto;
}

.placing-left > div,
.placing-right > div,
.placing-left-top > div,
.placing-right-top > div {
  margin-bottom: auto;
}

.placing-left > div,
.placing-right > div,
.placing-left-bottom > div,
.placing-right-bottom > div {
  margin-top: auto;
}

.space > :global(.block-content > .block-wrap) {
  padding: 10px;
}

:global(.diagram-canvas.mode-view) .element:global(.focused > .block-content > .block-wrap > .diagram-element) {
  pointer-events: none !important;
}

.FL, .FT, .FR, .FB {
  position: absolute;
  pointer-events: all;
}

:global(.layout-main.element-dragging) .FL,
:global(.layout-main.element-dragging) .FT,
:global(.layout-main.element-dragging) .FR,
:global(.layout-main.element-dragging) .FB,
:global(.layout-main.element-dropping) .FL,
:global(.layout-main.element-dropping) .FT,
:global(.layout-main.element-dropping) .FR,
:global(.layout-main.element-dropping) .FB {
  display: none;
}

.FL, .FR {
  top: 0;
  bottom: 0;
  width: 8px;
}

.FT, .FB {
  left: 0;
  right: 0;
  height: 8px;
}

.FL { left: -3px; }
.FR { right: -3px; }
.FT { top: -3px; }
.FB { bottom: -3px; }
