.element {
  font-size: 0;
}

.element > :global(.block-content) {
  z-index: unset !important;

  display: inline-grid;
  display: -o-inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: -webkit-inline-grid;
}

.horizontal-left > :global(.block-cell) { justify-content: start; -webkit-justify-content: start; }
.horizontal-right > :global(.block-cell) { justify-content: end; -webkit-justify-content: end; }
.horizontal-center > :global(.block-cell) { justify-content: center; -webkit-justify-content: center; }

.vertical-top > :global(.block-cell) { align-items: start; -webkit-align-items: start; }
.vertical-bottom > :global(.block-cell) { align-items: end; -webkit-align-items: end; }
.vertical-center > :global(.block-cell) { align-items: center; -webkit-align-items: center; }

.size-s > :global(.block-cell) { --cell-margin: 10; }
.size-m > :global(.block-cell) { --cell-margin: 24; }
.size-l > :global(.block-cell) { --cell-margin: 40; }