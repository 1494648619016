.element {
  --transparent: var(--background-color);

  white-space: nowrap;

  color: var(--color-text, var(--color));
}

:global(.block-cell > .diagram-element).element {
  margin: max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px + 4px)) max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px)) !important;
}

:global(.block-cell > .diagram-element).element > :global(.block-arrangement .cc),
:global(.block-cell > .diagram-element).element > :global(.block-arrangement:before) {
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}

.element > :global(.block-content) {
  width: max-content;
}

.text,
.input :global(.ql-editor) {
  padding: 2px 4px;
}

:global(.text-vertical) > .text,
:global(.text-vertical) > .input :global(.ql-editor) {
  padding: 4px 2px;
}

.text,
.input {
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.element > :global(.text-vertical) {
  width: auto;
  height: max-content;
}
