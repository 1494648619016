.color-dark { --color-owner: #8b8d9a; --background: #e3e6ed; }
.color-yellow { --color-owner: #c6a949; --background: #ffefbb; }
.color-orange { --color-owner: #e29c79; --background: #ffe0d0; }
.color-red { --color-owner: #c37d8e; --background: #ffd1dd; }
.color-green { --color-owner: #7bb093; --background: #d1f2e0; }
.color-sky { --color-owner: #8b9fbe; --background: #dfedff; }
.color-blue { --color-owner: #8793b8; --background: #d4ddf5; }
.color-purple { --color-owner: #ae8cc9; --background: #efdaff; }
.color-grey { --color-owner: #a3a3b5; --background: #efeff9; }

.element {
  --transparent: var(--background-color);
  --color-block: var(--null);
  --color-text: var(--null);
  --color-highlight: var(--null);
  --color-solid: var(--null);
}

.element > :global(.block-content) {
  min-width: 48px;
  min-height: 48px;
}

.element:global(.wrapping > .block-content) {
  min-width: 24px;
  min-height: 24px;
}

.element > .wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;

  min-width: max-content;
}

.wrap > :global(.block-wrap) {
  padding: 10px;
}

.owner {
  top: unset !important;
  left: 10px !important;
  right: 24px !important;
  bottom: 6px !important;

  font-size: 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--color-owner);
}

.owner + .wrap > :global(.block-wrap) {
  padding-bottom: 26px !important;
}