.element > :global(.block-content) > .wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

:global(.block-cell > .diagram-element).element.inline {
  margin: max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px + 3px)) max(calc(var(--cell-margin) * var(--cell-margin) / 100 * 1px));
}

.element > :global(.block-content) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  column-gap: 6px;
  -webkit-column-gap: 6px;
  row-gap: 6px;
  -webkit-row-gap: 6px;
}

.placing-top {
  flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
}
.placing-left {
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}
.placing-right {
  flex-direction: row;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
}
.placing-bottom {
  flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
}

.align-left, .align-top {
  align-items: start;
  -webkit-align-items: start;
}
.align-right, .align-bottom {
  align-items: end;
  -webkit-align-items: end;
}
.align-center {
  align-items: center;
  -webkit-align-items: center;
}

:global(.diagram-canvas:not(.diagram-space)) .opened > :global(.block-content),
:global(.diagram-canvas:not(.diagram-space)) .opened > :global(.block-content) > .wrap > :global(.block-wrap > .nested) {
  pointer-events: all;
}

:global(.diagram-canvas:not(.diagram-space)) .opened > :global(.block-content) {
  z-index: unset;
}
