.element > :global(.block-render) {
  z-index: 22;
} 

.element > :global(.block-render path) {
  fill: var(--color);
}

:global(.layout-main:not(.element-dragging)) .element:global(.selected > .block-render path) {
  pointer-events: all !important;
  pointer-events: bounding-box !important;
}

.element > :global(.block-content) {
  pointer-events: none;
}

:global(.diagram-canvas.mode-view) .element:global( > .block-render path) {
  pointer-events: none !important;
}
